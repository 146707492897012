exports.components = {
  "component---src-404-pages-404-js-content-file-path-users-touffi-dev-portfolio-data-pages-snippet-404-fr-mdx": () => import("./../../../src/404/pages/404.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/snippet/404.fr.mdx" /* webpackChunkName: "component---src-404-pages-404-js-content-file-path-users-touffi-dev-portfolio-data-pages-snippet-404-fr-mdx" */),
  "component---src-404-pages-404-js-content-file-path-users-touffi-dev-portfolio-data-pages-snippet-404-mdx": () => import("./../../../src/404/pages/404.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/snippet/404.mdx" /* webpackChunkName: "component---src-404-pages-404-js-content-file-path-users-touffi-dev-portfolio-data-pages-snippet-404-mdx" */),
  "component---src-gallery-pages-gallery-js": () => import("./../../../src/gallery/pages/Gallery.js" /* webpackChunkName: "component---src-gallery-pages-gallery-js" */),
  "component---src-home-pages-home-js-content-file-path-users-touffi-dev-portfolio-data-pages-snippet-home-fr-md": () => import("./../../../src/home/pages/Home.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/snippet/home.fr.md" /* webpackChunkName: "component---src-home-pages-home-js-content-file-path-users-touffi-dev-portfolio-data-pages-snippet-home-fr-md" */),
  "component---src-home-pages-home-js-content-file-path-users-touffi-dev-portfolio-data-pages-snippet-home-md": () => import("./../../../src/home/pages/Home.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/snippet/home.md" /* webpackChunkName: "component---src-home-pages-home-js-content-file-path-users-touffi-dev-portfolio-data-pages-snippet-home-md" */),
  "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-about-fr-md": () => import("./../../../src/staticPages/pages/StaticPage.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/static/about.fr.md" /* webpackChunkName: "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-about-fr-md" */),
  "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-about-md": () => import("./../../../src/staticPages/pages/StaticPage.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/static/about.md" /* webpackChunkName: "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-about-md" */),
  "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-contact-fr-mdx": () => import("./../../../src/staticPages/pages/StaticPage.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/static/contact.fr.mdx" /* webpackChunkName: "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-contact-fr-mdx" */),
  "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-contact-mdx": () => import("./../../../src/staticPages/pages/StaticPage.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/static/contact.mdx" /* webpackChunkName: "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-contact-mdx" */),
  "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-legal-fr-md": () => import("./../../../src/staticPages/pages/StaticPage.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/static/legal.fr.md" /* webpackChunkName: "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-legal-fr-md" */),
  "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-legal-md": () => import("./../../../src/staticPages/pages/StaticPage.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/static/legal.md" /* webpackChunkName: "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-legal-md" */),
  "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-thank-you-fr-md": () => import("./../../../src/staticPages/pages/StaticPage.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/static/thank-you.fr.md" /* webpackChunkName: "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-thank-you-fr-md" */),
  "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-thank-you-md": () => import("./../../../src/staticPages/pages/StaticPage.js?__contentFilePath=/Users/touffi/dev/portfolio/data/pages/static/thank-you.md" /* webpackChunkName: "component---src-static-pages-pages-static-page-js-content-file-path-users-touffi-dev-portfolio-data-pages-static-thank-you-md" */)
}

