import '../../scss/styles.scss';

import React from 'react';

import ConsentContextProvider from '../cookieConsent/ConsentContext';
import GaToggle from '../ui/GaToggle';

import { I18nextProvider } from 'react-i18next';

const wrapRootElement = (element, i18n) => {
	return (
		<I18nextProvider i18n={i18n}>
			<ConsentContextProvider>
				<GaToggle>{element}</GaToggle>
			</ConsentContextProvider>
		</I18nextProvider>
	);
};

export default wrapRootElement; /* */
