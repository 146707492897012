/* eslint-env node */
const gaId = 'UA-161891804-1';

module.exports = {
	siteMetadata: {
		title: `Christophe Noël Photography`,
		titleBrand: 'Christophe Noël Photography',
		description: `Landscape photography and other curiosities, by Christophe Noël.`,
		author: `Christophe Noël`,
		copyrightAuthor: `Christophe Noël`,
		siteUrl: `https://cn-photos.com`,
		gaTrackingId: gaId,
		fbAppId: '532389617454311',
	},
	plugins: [
		`gatsby-plugin-react-helmet`,
		`gatsby-transformer-json`,
		{
			resolve: `gatsby-source-filesystem`,
			options: {
				path: `${__dirname}/data`,
			},
		},
		`gatsby-plugin-image`,
		`gatsby-plugin-sharp`,
		`gatsby-transformer-sharp`,
		`gatsby-plugin-sass`,
		{
			resolve: `gatsby-plugin-sharp`,
			options: {
				stripMetadata: false,
				defaultQuality: 75,
			},
		},
		{
			resolve: `gatsby-plugin-robots-txt`,
			options: {
				host: `https://cn-photos.com`,
				sitemap: `https://cn-photos.com/sitemap.xml`,
				policy: [{ userAgent: '*', allow: ['/'] }],
			},
		},
		`gatsby-remark-images`,
		{
			resolve: `gatsby-plugin-mdx`,
			options: {
				extensions: [`.mdx`, `.md`],
				gatsbyRemarkPlugins: [
					{
						resolve: `gatsby-remark-images`,
						options: {
							maxWidth: 400,
							maxHeight: 400,
							fit: 'inside',
							showCaptions: ['title'],
							backgroundColor: 'none',
							disableBgImage: false,
							withWebp: true,
							linkImagesToOriginal: false,
						},
					},
				],
			},
		},
		{
			resolve: `gatsby-plugin-manifest`,
			options: {
				name: `CN Photos`,
				short_name: `CN Photos`,
				start_url: `/`,
				background_color: `#F4F4F4`,
				theme_color: `#005163`,
				display: `standalone`,
				icon: `src/favicon.png`,
			},
		},
		{
			resolve: `gatsby-plugin-google-analytics`,
			options: {
				// https://www.gatsbyjs.org/packages/gatsby-plugin-google-analytics/
				trackingId: gaId,
				head: true,
				anonymize: true,
				respectDNT: true,
				pageTransitionDelay: 0,
			},
		},
	],
	flags: {
		FAST_DEV: true,
		PRESERVE_FILE_DOWNLOAD_CACHE: true,
	},
};
