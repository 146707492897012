import PropTypes from 'prop-types';

import i18n from 'i18next';
import wrapRootElementCommon from './src/core/wrapRootElement';
import initI18n from './src/core/i18n/initI18next';

const wrapRootElement = ({ element }) => {
	initI18n();
	return wrapRootElementCommon(element, i18n);
};
wrapRootElement.propTypes = {
	element: PropTypes.element.isRequired,
};

export { wrapRootElement };

import '@fontsource/merriweather/400.css';
import '@fontsource/merriweather/700.css';
