import Cookies from 'js-cookie';

const COOKIE_NAME = 'cn-photo';
const COOKIE_EXPIRE = 365; // consent is valid for 12 months

const defaultCookie = {
	consentGiven: true,
};

// todo: base64 encode

const consentCookie = {
	create() {
		Cookies.set(COOKIE_NAME, JSON.stringify(defaultCookie), {
			expires: COOKIE_EXPIRE,
		});
	},

	exists() {
		return typeof Cookies.get(COOKIE_NAME) !== 'undefined';
	},

	isConsentGiven() {
		if (!this.exists()) {
			return true; // duh! We take consent for granted...
		}
		const cookie = JSON.parse(Cookies.get(COOKIE_NAME));
		return cookie.consentGiven;
	},

	toggleConsent() {
		const cookie = JSON.parse(Cookies.get(COOKIE_NAME));
		cookie.consentGiven = !cookie.consentGiven;
		Cookies.set(COOKIE_NAME, JSON.stringify(cookie));
	},
};

export default consentCookie;
