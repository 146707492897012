/* eslint-env node */
module.exports = {
	allLang: ['en', 'fr'],
	defaultLang: 'en',
	defaultI18nextNs: 'translation',
	images: {
		home: {
			sizes: [
				'(min-width: 576px) calc(33vw - 60px)',
				'(min-width: 768px) calc(33vw - 340px)',
				'calc(50vw - 5px)',
			],
			srcSetBreakpoints: [185, 286, 372, 534, 684, 889, 1092],
		},
		galleryPhoto: {
			sizes: [
				'(min-width: 576) calc(100vw - 40px)',
				'(min-width: 768) calc(100vw - 320px)',
				'100vw',
			],
			srcSetBreakpoints: [375, 448, 750, 975, 1280, 1600, 1920],
		},
	},
	sitemapPath: 'static/sitemap.xml',
};
