/* global gaOptout */
import { useContext, useEffect } from 'react';
import gatsbyConfig from '../../gatsby-config';
import Cookies from 'js-cookie';
import { ConsentContext } from '../cookieConsent/ConsentContext';

const disableStr = 'ga-disable-' + gatsbyConfig.siteMetadata.gaTrackingId;

const canOptout = () => {
	return typeof gaOptout === 'function';
};

const hasOptout = () => {
	return Cookies.get(disableStr) || window[disableStr];
};

const gaOptin = () => {
	Cookies.remove(disableStr);
	delete window[disableStr];
};

const GaToggle = ({ children }) => {
	const { hasCookieConsent } = useContext(ConsentContext);

	useEffect(() => {
		if (!hasCookieConsent && canOptout()) {
			gaOptout();
		} else if (hasCookieConsent && hasOptout()) {
			gaOptin();
		}
	});

	return children;
};

export default GaToggle;
