import React, { useState } from 'react';
import PropTypes from 'prop-types';

import consentCookie from './consentCookie';

const ConsentContext = React.createContext({
	hasCookieConsent: consentCookie.isConsentGiven(),
	toggleCookieConsent: () => {},
});

const ConsentContextProvider = ({ children }) => {
	const [hasCookieConsent, setHasCookieConsent] = useState(
		consentCookie.isConsentGiven()
	);

	const toggleCookieConsent = () => {
		consentCookie.toggleConsent();
		setHasCookieConsent(consentCookie.isConsentGiven());
	};

	return (
		<ConsentContext.Provider
			value={{ hasCookieConsent, toggleCookieConsent }}
		>
			{children}
		</ConsentContext.Provider>
	);
};

ConsentContextProvider.propTypes = {
	children: PropTypes.element.isRequired,
};

export default ConsentContextProvider;
export { ConsentContext };
