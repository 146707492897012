/* eslint-env node */
const config = require('../../config');

// We bundle the translations instead of using a backend to avoid the following flow:
// - load server-side rendered HTML with translated strings
// - start re-rendering client side
// - fetch translations from backend and block rendering (<Suspense>...), which cause a blank page (!)
// - finish re-rendering client side... re-draw the exact same page after the white flash
const langEn = require('../../../static/translation/en.json');
const langFr = require('../../../static/translation/fr.json');

module.exports = {
	debug: false,
	fallbackLng: config.defaultLang,
	lng: config.defaultLang,
	ns: config.defaultI18nextNs,
	defaultNS: config.defaultI18nextNs,
	fallbackNS: config.defaultI18nextNs,
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
	resources: {
		en: {
			translation: langEn,
		},
		fr: {
			translation: langFr,
		},
	},
	react: {
		useSuspense: false,
	},
	saveMissing: false,
};
